@import '../stylesheets/styles';


.icon-button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $dark-neutral;
  transition: all 0.2s ease-in;

  svg {
    color: $light-neutral;
    margin-right: 10px;
    transition: all 0.2s ease-in;
  }

  &:hover {
    color: $bats-hover;
    svg {
      color: $bats-hover;
    }
  }

  &:active, &.active {
    color: $active-default;
    svg {
      color: $active-default;
    }
  }

  &:disabled, &.disabled {
    color: $neutral;
    pointer-events: none;
    svg {
      color: $neutral;
    }
  }

  .message-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in;

    .unread-message-count {
      position: absolute;
      text-align: center;
      top: -7px;
      right: 4px;
      padding: 0.5px 1px;
      min-width: 15px;
      min-height: 8px;
      border-radius: 50px;
      border: none;
      background-color: $error;
      @extend %annotation;
      font-weight: 700;
      font-size: 10px;
      color: $white;
      transition: all 0.2s ease-in;
    }
  }
}
