@import 'src/styles';

.account-card {
  padding: 20px 30px 20px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
  background-color: $white;

  box-sizing: border-box;
  width: 100%;
  min-width: 280px;

  &.list {
    @extend %list-item;

    .reserved, .balance, .account {
      text-align: left;
    }

    .wrapper-icon {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px;
    }

    .icon-background {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 6px;
      background-color: $bg-2;

      .icon {
        color: $active-default;
      }

      &:hover {
        .icon {
          color: $white;
        }
        background-color: $active-hover;
      }

      &:active {
        .icon {
          color: $white;
        }
        background-color: $active-active;
      }
    }
  }

  &.list > :nth-child(n) {
    flex: 1;
    text-align: center;
  }

  .left-side {

    .account {
      @extend %body;
      color: $neutral;
      margin-bottom: 10px;
      word-break: break-word;
    }

    .wrapper-balance {
      display: flex;
      margin: 0;
      padding: 0;
    }

    .balance {
      @extend %heading-1;
      color: $header;
      margin-right: 5px;
    }
    .currency {
      @extend  %heading-1;
      color: $neutral;
    }
  }

  .buttons-wrapper{
    display: flex;
    gap: 15px;
  }
}

@media screen and (max-width: 1242px) {
  .account-card {
    width: 100%;
  }
}

@include mobile {
  .account-card {
    width: 100%;
  }

  .account-card.list {
    padding: 0;

    .account-card-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      min-width: 210px;
      padding: 10px;
    }
  }
}