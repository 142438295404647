@import '../stylesheets/styles';

@mixin btnFileColor($back, $text, $icon:null) {
  background-color: $back;
  color: $text;
  .icon {
    @if $icon != null {
      color: $icon;
    } @else {
      color: $text;
    }
  }
}

.pdf-button {
  padding: 0 10px;
  display: flex;
  gap: 7px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: $bg-2;
  color: $active-default;
  border: none;
  @extend %body;
  transition: all 0.2s ease-in-out;

  &.disabled {
    pointer-events: none;
    opacity: .65;
  }

  .icon {
    color: $active-default;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    @include btnFileColor($bats-hover, $white);
  }

  &:active {
    @include btnFileColor($active-default, $white);
  }

  &.input {
    border: 1px solid $light-neutral;
    @include btnFileColor(transparent, $neutral, $dark);

    &:hover {
      @include btnFileColor($bg-2, $active-default);
    }
  }
}

@include mobile {
  .pdf-button.input {
    width: 100%;
    padding: 0 15px 0 20px;
    justify-content: space-between;
    @include btnFileColor(transparent, $active-default);
  }
}