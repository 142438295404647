@import './src/styles';

.header {
  position: sticky;
  top: 0;
  min-height: 120px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-neutral;
  background-color: $background-color;
  z-index: 2;

  .header-content-wrapper {
    padding: 29px 50px;
    max-width: $desktop-width;
    display: flex;
    margin: 0 auto;
    width: 100%;

    .logo-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 223px;
      height: 60px;
      transition: all 0.3s ease-in-out;

      .header-logo {
        max-width: 100%;
        max-height: 100%;
      }

      &.active {
        padding: 29px 50px 29px 38px;
        .header-logo {
          transition: all 0.3s ease-in-out;
          margin-right: 35px;
        }
      }
    }
  }

  .header-user-panel-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  .header-user-panel-wrapper.collapsed {
    opacity: 0;
    width: 0;
    transition: all 0.3s ease-in-out;
  }

  .logo-wrapper.collapsed {
    width: 95px;
    min-width: 95px;
    height: 60px;
    transition: all 0.3s ease-in-out;
  }

  .header-logo {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .header-logo.collapsed {
    min-width: unset;
    transition: all 0.3s ease-in-out;
  }

  .header-menu-links {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
  }

  .account-dropdown-container {
    margin-left: auto;

    input {
      width: 100%;
      min-width: 120px;
    }

    .current-account-name {
      color: $header;
      line-height: 60px;
      padding-right: 30px;
    }
  }

  .account-dropdown {
    display: flex;
    width: fit-content;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;

    .dropdown-choice {
      padding-left: 20px;
    }

    .dropdown-choice-value {
      @extend %heading-2;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .account-dropdown >:nth-child(2) {
    padding: 0 30px 0 20px;
    background-color: $background-color;
    border-color: $light-neutral;
  }

  .header-menu-button {
    margin-right: 15px;
  }

  .user-panel {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-left: 40px;

    p {
      white-space: nowrap;
    }
  }

  .user-panel.collapsed {
    gap: 15px;
    margin-left: 20px;
  }
}

.open-acc {
  display: flex;
  gap: 16px;
  p {
    @extend %heading-2;
    color: $active-default;
  }
}

@include desktop-breakpoint {
  .header {
    .header-logo {
      margin-right: 63px;
    }
    .user-panel {
      gap: 15px;
    }
  }
}

@include mobile {
  .header {
    min-height: 79px;
    border-bottom: none;
    background-color: $white;
    box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);

    .header-content-wrapper, .header-content-wrapper.active {
      align-items: center;
      height: 80px;
      padding: 0 20px;
    }

    .header-menu-links {
      justify-content: flex-end;
    }

    .header-logo {
      margin-right: 44px;
    }
  }
}




