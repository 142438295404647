@import './variables';

.link-dark {
  text-decoration: none;
  color: $neutral;
  transition: all 0.2s ease-in;
  border-bottom: 1px solid transparent;
  font-size: $font-size-smaller;

  &:hover {
    color: $active-hover;
  }

  &:active {
    color: $active-active;
  }
}

.link-normal {
  text-decoration: none;
  color: $active-default;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease-in;

  &:hover {
    color: $active-hover;
  }

  &:active {
    color: $active-active;
  }

  &:disabled {
    color: $neutral;
    cursor: initial;
  }
}

.dropdown-choice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  transition: all 0.2s ease-in-out;

  .dropdown-choice-currency {
    margin-right: 16px;
    @extend %body;
    font-weight: 600;
    color: $active-default;
  }

  .dropdown-choice-value {
    max-width: 90%;
    width: 100%;
  }

  .dropdown-choice-value-with-currency {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .checkbox {
    .background{
      stroke: $light-neutral;
      fill: $white;
      transition: all 0.2s ease-in-out;
    }
    .check {
      color: $light-neutral;
      transition: all 0.2s ease-in-out;
    }
  }

  &:hover {
    background-color: $light;
    .checkbox{
      transition: all 0.2s ease-in-out;
      .background{
        stroke: $active-hover;
        fill: $bg-2;
      }
      .check {
        color: #E4C6F4;
      }
    }
  }

  &.active {
    background-color: $bg-2;
    color: $active-default;
    .checkbox{
      .background{
        stroke: $active-default;
        fill: $active-default;
      }
      .check {
        color: $white;
      }
    }

    &:hover {
      background-color: $light;
      .checkbox{
        .background{
          stroke: $active-hover;
          fill: $white;
        }
        .check {
          color: $active-default;
        }
      }
    }
  }

  &.disabled {
    color: $light-neutral;
    cursor: initial;
    &.active {
      .checkbox {
        .background {
          stroke: $light-neutral;
          fill: $light-neutral;
        }
      }
    }
  }
}

.flex {
  display: flex;
}

%svg-icon {
  cursor: pointer;
  color: $light-neutral;
  transition: all 0.2s ease-in;

  &:hover {
    color: $bats-hover;
  }

  &:active {
    color: $active-default;
  }
}

%list-item {
  width: 100%;
  padding: 20px 0 20px 0;
  display: flex;
  align-items: center;
  box-shadow: none;
  background-color: transparent;
  border-radius: 0;
  border-bottom:  1px solid $light-neutral;
  color: $header;

  .card-mobile-text, .template-mobile-text {
    display: none;
  }
}

%list-item > :nth-child(n) {
  flex: 1;
  text-align: left;
}

@include mobile {
  %list-item {
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
    border-radius: 6px;
    border-bottom: none;
    align-items: flex-start;
    padding: 10px;

    .card-mobile-text, .template-mobile-text {
      display: inline;
      color: $neutral;
    }
  }
  %list-item > :nth-child(n) {
    display: flex;
    padding: 10px 0;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid $light-neutral;
  }

  %list-item > :last-child {
    border-bottom: none;
  }
}
