@import 'src/uikit/stylesheets/styles.scss';

.template-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .template-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    max-width: 510px;

    .template-form-tabs-wrapper {
      display: flex;
      align-self: flex-start;
      width: 100%;
      height: 60px;
      margin-top: 20px;

      .template-form-tab-button {
        padding: 20px 10px;
        border-bottom: 1px solid $light-neutral;
        width: 50%;
        white-space: nowrap;
        &:active, &.active {
          border-bottom: 1px solid $active-default;
        }
      }
    }

    .template-inputs-evenly-wrapper {
      width: 100%;

      .template-form-input {
        margin-top: 19px;
      }
    }

    .template-inputs-row-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 30px;
      width: 100%;

      .template-form-input {
        margin-top: 19px;
      }
    }

    .template-inputs-row-wrapper >:nth-child(n) {
      width: 240px;
      @media (max-width: 902px) {
        width: 100%;
      }
    }

    .inputs-wrapper > :last-child, .template-inputs-row-wrapper.Company > :first-child {
      flex: 1;
      .input {
        width: 100%;
        min-width: 150px;
      }
    }

    .inputs-wrapper > :first-child, .template-inputs-row-wrapper.Company > :last-child {
      flex: 2;
      .input {
        width: 100%;
        min-width: 320px;
      }
    }

    .template-inputs-row-wrapper.Individual > :nth-child(n) {
      flex: 1;
      .input {
        width: 100%;
        min-width: 150px;
      }
    }

  }
}

@include mobile {
  .template-form-wrapper {
    .template-form {
      gap: 24px;

      .template-form-tabs-wrapper {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-top: 6px;

        .template-form-tab-button {
          width: 50%;
          white-space: pre-wrap;
          padding: 20px 20px 20px 0;
        }
      }

      .inputs-wrapper, .template-inputs-row-wrapper {
        width: 100%;

        .send-transfer-button {
          margin-top: 19px;
        }
      }

      .template-inputs-row-wrapper:last-of-type {
        flex-wrap: wrap-reverse;
      }

      .inputs-wrapper > :nth-child(n), .template-inputs-row-wrapper >:nth-child(n) {
        width: 100%;
      }

      .inputs-wrapper> :first-child,
      .template-inputs-row-wrapper.Company> :last-child {
        flex: 2;

        .input {
          width: 100%;
          min-width: 250px;
        }
      }
    }
  }
}