@import '../stylesheets/styles';

.security-wrapper {
  display: flex;
  justify-content: center;
  gap: 8px;

  .security-cell {
    width: 40px;
    height: 50px;
    text-align: center;
    border: 1px solid $light-neutral;
    border-radius: 6px;

    &[type="number"] {
        -moz-appearance: textfield;
    }
    
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}


