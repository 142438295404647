@import 'src/styles';

.dashboard {
  display: flex;
  gap: 50px;

  .card-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 25px;
    width: 100%;
    box-sizing: border-box;
  }

  .account-info {
    height: fit-content;
  }
}

@media screen and (max-width: 1242px) {
  .dashboard {
    .card-wrapper {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@include mobile {
  .dashboard {
    .card-wrapper {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }
}
