@import 'src/uikit/stylesheets/styles.scss';

.templates-table-row-wrapper {
  border-radius: 8px;
  box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
  background-color: $white;
  width: 300px;
  gap: 20px;

  &.list {
    padding: 10px 30px;
    @extend %list-item;
    width: 100%;
    word-break: break-word;

    .actions {
      max-width: 136px;
      text-align: left;
    }

    .payee-requisites {
      min-width: 200px;
      text-align: left;
    }

    .template-name {
      max-width: 200px;
      text-align: left;
    }

    .payee-name {
      max-width: 200px;
      text-align: left;
    }

    .wrapper-icon {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      max-width: 136px;
    }

    .icon-background {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 6px;
      background-color: $bg-2;

      .icon {
        color: $active-default;
      }

      &:hover {
        .icon {
          color: $white;
        }
        background-color: $active-hover;
      }

      &:active {
        .icon {
          color: $white;
        }
        background-color: $active-active;
      }
    }
  }

  &.list > :nth-child(n) {
    flex: 1;
    text-align: center;
  }

  .left-side {

    .account {
      @extend %body;
      color: $neutral;
      margin-bottom: 10px;
      word-break: break-word;
    }

    .payee-requisites {
      @extend  %heading-1;
      color: $neutral;
    }
  }
}

@include mobile {
  .templates-table-row-wrapper {
    width: 100%;
  }

  .templates-table-row-wrapper.list {
    padding: 0;
    max-width: unset;
    min-width: unset;
    gap: 0;

    .templates-table-row-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      min-width: 210px;
      padding: 10px;
      max-width: unset;
      gap: 10px;
    }
  }
}