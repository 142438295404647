@import 'src/styles';


.templates-container {
  min-height: 650px;
  padding: 100px 30px 30px 30px;
  justify-content: start;

  .actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;

    .add-new-template-button {
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 20px;
      box-sizing: border-box;
      @extend %heading-2;
      font-weight: 600;
    }

    .search-input {
      max-width: 235px;
    }
  }

  .templates-list {
    width: 100%;

    .templates-head {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $bg-light;
      padding: 10px 30px;
      gap: 20px;


      .head-template-name {
        text-align: left;
        max-width: 200px;
      }

      .head-payee-requisites {
        text-align: left;
        min-width: 228px;
      }

      .head-payee-name {
        text-align: left;
        max-width: 200px;
      }

      .head-actions {
        text-align: left;
        max-width: 136px;
      }
    }

    .templates-head > :nth-child(n) {
      flex: 1;
      text-align: left;
    }
  }
}


@include mobile {
  .templates-container {
    padding: 0 0 15px 0;

    .actions-wrapper {
      gap: 20px;
      margin-bottom: 20px;

      .add-new-template-button {
        width: 100%;
        justify-content: center;
      }

      .search-input {
        width: 100%;
        max-width: unset;
      }
    }
  }
  .templates-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}