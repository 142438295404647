@import './src/styles';

.registration-form {
  @extend %auth-container;

  .registration-icon {
    margin-top: 40px;
  }

  .registration-title {
    @extend %auth-title;
    margin-bottom: 20px;

    &.user {
      margin-top: 22px;
      margin-bottom: 40px;
    }
  }

  .registration-radio-group {
    margin-bottom: 32px;
  }

  .form-data {
    max-width: 350px;

    >* {
      margin-bottom: 25px;
    }

    .form {
      margin-bottom: 0;

      >* {
        margin-bottom: 39px;
        &:nth-child(3), 
        &:nth-child(4) {
          margin-bottom: 25px;
        }
      }
      .user {
        margin-bottom: 20px;
      }
      //.captcha-wrapper {
      //  transform:scale(1.15);
      //  transform-origin: 0 0;
      //}
    }
  }

  .registration-description {
    text-align: center;
    width: 305px;
    color: $neutral;
    font-size: $font-size-smaller;
    margin-bottom: 30px;

    &.dark  {
      color: $dark;
    }

    strong {
      color: $dark;
    }
  }

  .register-name-wrapper {
    display: flex;
    gap: 20px;

    .input-wrapper {
      width: 165px;
      // height: 60px;
    }
  }

  .form-description {
    padding: 0 16px;
    color: $bg-dark;
    text-align: center;
    letter-spacing: 0;
    line-height: 17px;
  }

  .security-wrapper {
    margin-bottom: 30px;
  }

  .alert-message {
    margin-bottom: 25px;
    animation: 0.3s ease-in-out marginBottom25;
  }

  @keyframes marginBottom25 {
    from {   margin-bottom: 0 }
    to {   margin-bottom: 25px }
  }

  .back-button {
    margin-bottom: 35px;
  }

  .register-createAccount-wrapper {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 0 52px;
    font-size: $font-size-small;
    line-height: 140%;
    color: $black;
  }

  .button {
    margin-bottom: 16px;
  }
  .register-account-text {
    margin-bottom: 0;
    text-align: center;
    color: $black;
    font-size: 14px;
  }

  .form-data{
    .text-center {
      margin-bottom: 16px;
    }
    hr {
      margin-bottom: 10px;
    }
  }
}

@include mobile {
  .registration-form {
    .register-name-wrapper {
      flex-direction: column;
      gap: 28px;
      height: fit-content;
      .input-wrapper {
        width: 100%;
      }
    }

    .react-tel-input {
      .country-list {
        width: 280px;
      }
    }
  }
}