@import 'src/styles';

.exchange-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  padding: 0 10px;

  .exchange-wrapper-alert {
    align-items: center;
    margin-bottom: 50px;
  }

  .exchange-form-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 24px;

    .exchange-inputs-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
      gap: 30px;
    }

    .switch-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-top: 14px;
      border-radius: 8px;
      background-color: $light;
      transition: background-color 0.2s ease-in;
      svg {
        margin: 0;
        path {
          color: $active-default;
          transition: all 0.2s ease-in;
        }
      }

      &:hover {
        background-color: $bats-hover;
        svg {
          path {
            color: $white;
          }
        }
      }

      &:active, &.active {
        background-color: $active-default;
        svg {
          path {
            color: $white;
          }
        }
      }
    }

    .exchange-rate-commission-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 16px 0;
      gap: 8px;
      border-top: 1px solid $light-neutral;
      border-bottom: 1px solid $light-neutral;

      .exchange-rate-commission-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @extend %caption;

        .exchange-rate-commission-title {
          color: $dark;
        }

        .exchange-rate-commission-value {
          color: $neutral;
        }
      }
    }
  }

  .exchange-button-wrapper {
    max-width: 300px;
    width: 100%;
    margin-top: 30px;

    button {
      width: 100%;
    }
  }

  .input-sub-text {
    text-align: right;
    margin-right: 10px;
  }
  .input-error {
    text-align: left;
    input {
      color: $error;
    }
  }

  .exchange-rate-timer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 100%;
    margin-top: 24px;
    padding: 14px;
    border-radius: 8px;
    background-color: $bg-2;

    .exchange-rate-timer-description {
      text-align: center;
      @extend %caption;
      font-weight: 600;
      color: $header;
    }

    .exchange-rate-timer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .exchange-rate-timer-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .exchange-rate-timer-loader {
          svg {
            margin: 0;
          }
        }

        .exchange-rate-timer-remaining-time {
          @extend %heading-2;
          font-weight: 600;
          color: $header;
        }
      }
    }
  }
}


@include desktop-to-mobile {
  .exchange-wrapper {
    align-items: center;
    min-width: 340px;
    padding: 20px 20px 0 20px;

    .exchange-wrapper-alert {
      align-items: initial;
    }

    .exchange-form-wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .switch-button {
        width: 60px;
        height: 60px;
        margin-top: 0;
        margin-bottom: 20px;
        border-radius: 16px;
      }
    }

    .exchange-rate-commission-wrapper {
      max-width: 300px;
    }

    .exchange-rate-timer-wrapper {
      max-width: 300px;

      .exchange-rate-timer-description {
        @include annotation();
      }
    }
  }
}

@include mobile {
  .exchange-wrapper {
    align-items: center;
    min-width: 340px;
    padding: 20px 20px 0 20px;

    .exchange-wrapper-alert {
      align-items: initial;
    }

    .exchange-form-wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .switch-button {
        width: 60px;
        height: 60px;
        margin-top: 0;
        margin-bottom: 20px;
        border-radius: 16px;

      }
    }

    .exchange-button-wrapper {
      margin-top: 20px;
    }

    .exchange-rate-commission-wrapper {
      max-width: 300px;
      margin-top: 10px;
    }

    .exchange-rate-timer-wrapper {
      max-width: 300px;

      .exchange-rate-timer-description {
        @include annotation();
      }
    }
  }
}